/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 14.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

.preview-container-inner {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    justify-content: space-between;
    align-items: stretch;
}
.preview-container-inner > .containerTop {
}
.preview-container-inner > .containerTop > .containerTopInner {
    display: flex;
}
.preview-container-inner > .containerMiddle {
    flex-grow: 1;
    height: calc(100vh - 600px);
}
.preview-container-inner > .containerMiddle > .containerMiddleInner {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.preview-container-inner > .containerMiddle > .containerMiddleInner > .containerLeft {
    width: 200px;
}
.preview-container-inner > .containerMiddle > .containerMiddleInner > .containerLeft > .containerLeftInner {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 10px;
}
.preview-container-inner > .containerMiddle > .containerMiddleInner > .containerKonva {
    position: relative;
    width: calc(100vw - 400px);
    min-height: 10px;
    flex-grow: 1;
    border: 1px solid rgba(0, 0, 0, 0.3);

}
.preview-container-inner > .containerMiddle > .containerMiddleInner > .containerRight {
    width: 200px;
}
.preview-container-inner > .containerMiddle > .containerMiddleInner > .containerRight > .containerRightInner {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 10px;
}
.preview-container-inner > .containerBottom {
    min-height: 100px;
}
.preview-container-inner > .containerBottom > .containerBottomInner {
    display: flex;
    flex-direction: row;
}

.draggable-item.drag {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    color: black;
    border-radius: 10px;
    padding: 10px;
    width: auto;
    pointer-events: none !important;
    z-index: 999999;
    text-align: center;
    transform: translate(-50%, -50%);
    transition: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    opacity: 0.5;
}

.draggable-item > div.title {
    position: absolute;
    top: 10px;
    left: 50%;
    background-color: rgba(0, 54, 134, 0.58);
    color: #ffffff;
    z-index: 99;
    border-radius: 5px;
    transform: translateX(-50%);
    text-align: center;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;
}

.draggable-item.drag > div {
    pointer-events: none !important;
}
.draggable-item.drag > div > canvas {
    pointer-events: none !important;
}

.draggable-item.drag.ok {
    opacity: 1;
    background-color: rgba(173, 255, 47, 0.55);
}

.draggable-item.drag.ok[data-type='3'],
.draggable-item.drag.ok[data-type='4'] {
    display: none;
}

.designerDropItem {
    overflow: hidden;
    margin: 5px;
    border-radius: 10px;
}

.draggable-item > .preview-container {
    display: flex;
    justify-content: center;
}

.hErrorMessage {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    border-radius: 10px;
    z-index: 99999;
    transform: translate(50px);
}