/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 7.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none !important;
    -ms-user-select: none !important;
    touch-action: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select-dropdown {
    z-index: 999999 !important;
}


.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background-image: url("https://www.streif-fensterbau.de/zentrale_dateien/logo.gif");
    background-size: 100%;
    background-repeat: no-repeat;
}

.site-layout .site-layout-background {
    position: relative;
    background: #fff;
    border-radius: 10px;
}

.site-page-header {
    border: 1px solid rgb(235, 237, 240);
    background: #fff !important;
    margin-bottom: 20px !important;
}

#exportContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-container {
    height: calc(100vh - 10vh);
    overflow: hidden;
    overflow-y: scroll;
}

.table-header, .table-item {
    display: flex;
}

.table-header {
    font-weight: bold;
}

.table-col {
    flex: 1;
    border: 1px solid black;
}
.table-header .table-col {
    flex: 1;
}

.table-col.no-borders {
    border: none !important;
}

.flex-container {
    display: flex;
}

.flex-vertical {
    flex-direction: column;
    float: left;
}