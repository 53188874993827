/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 12.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

body {
    margin: 0;
    padding: 0;
    background-color: #f3f5fa;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

h2 {
    font-size: 48px;
    line-height: 1;
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}

.w-centered {
    width: 298px;
}

.container-center {
    position: absolute;
    top: 50%;
    left: 50%;

    min-width: 100px;
    min-height: 100px;

    transform: translate(-50%, -50%);
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.topbar {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-top: 5px;
    padding-bottom: 5px;
}

.topbar-selector {
    display: none;
    position: fixed;
    top: 40px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 99999;
}

.topbar-selector > .tab-content > div {
    overflow-x: scroll;
    white-space: nowrap;
    table-layout: fixed;
}
.topbar-selector > .tab-content > div > ul {
    width: auto;
}
.topbar-selector > .tab-content > div > ul > li {
    display: inline-block;
}
.topbar-selector > .tab-content > div > ul > li > a {
    display: inline-block;
}
.topbar-selector.show {
    display: block;
}

.topbar h6 {
    margin-bottom: 0;
}

.preview-container {
    width: 100%;
    /*padding-top: 40px;*/
}

.preview-container .preview-zoomer {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
}

/*
.preview-container #container {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-left: 20px;
}
 */

.preview-container > .sidebar-container.left {
    position: fixed;
    left: -450px;
    top: 0;
    background-color: white;
    width: 400px;
    height: 100% !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.preview-container > .sidebar-container.left {
}
.preview-container > .sidebar-container.right {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.preview-container > .sidebar-container.left.show {
    left: 0;
}
.preview-container > .sidebar-container.show {
    right: 0;
}
.preview-container > .sidebar-container > div > .sidebar-header,
.preview-container > .sidebar-container > .sidebar-header {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.preview-container canvas {
    width: 100% !important;
    height: 100% !important;
}

.btn-save-overlay {
    display: none;
}
.saving-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    z-index: 1;
}
.saving-overlay > .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
}

.topbar-selector > .tab-content > div > ul > li > a {
    position: relative;
    padding: 2px;
    margin: 2px;
    border-radius: 10px;

}

.loader-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.pdf_viewer_container {
    position: relative;
}

.pdf_viewer {
    position: relative;
    z-index: 9999999999999;
    width: calc(100% - 10%);
    max-width: 100%;
    max-height: 500px;
    margin-bottom: 4em;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;
}

.text-semibold {
    letter-spacing: -.01em;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.71429;
}

.input-login {
    padding-left: 20px !important;
    border-radius: 10px !important;
    letter-spacing: -.01em;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #F4F4F4;
}

.input-login input {
    padding-left: 10px !important;
    font-weight: 600;
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #F4F4F4;
}
.input-login span {
    font-size: 1.1rem;
}


button.btn-login {
    background: #2A85FF;
    color: #FCFCFC;
    border: none;
    padding: 18px 40px !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    white-space: nowrap;
    letter-spacing: -.01em;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.6;
    transition: all 0.15s ease;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
}

.splitRowTitle {
    position: absolute;
    top: -12px; left: 10px;
    background-color: white;
    padding: 0 10px;
}
